body {
  font-family: "Lato", sans-serif;
  /*font-family: "Roboto Slab", serif;*/
  /*font-family: "Montserrat", sans-serif;*/
}

@tailwind base;
@tailwind components;

.backgroundImage {
  background-size: cover;
  background-image: url("andrej-lisakov-3A4XZUopCJA-unsplash-new.jpg");

  background-repeat: no-repeat;
  transition-duration: 1s;
  @apply min-h-screen bg-bottom pt-14;
}

.listItem {
  color: white;

  padding: 5px 10px;
  border-radius: 15px;
  background: #586369;
  width: max-content;
}

.tooltip {
  @apply invisible absolute grid place-items-start rounded shadow-lg p-1 bg-darkGrey/90 border-2 border-white text-white  -mt-[3.5rem] w-[3.5rem] text-xs;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

.formText {
  @apply xxs:w-[29.0625rem] w-full xxs:mx-auto   mb-8 shadow-sm px-2 outline-none border-2  focus:border-gray-500 focus:border-2;
}

.button {
  @apply rounded-lg grid place-items-center leading-none text-base w-40 h-12 border-2 border-[#586369] text-center 
   hover:border-black hover:scale-105 active:scale-95;
}

.skillIcon {
  @apply flex justify-center  gap-8;
}
@tailwind utilities;
